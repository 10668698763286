document.addEventListener('click', (event) => {
  if (!event.target) return

  const target = event.target as HTMLElement
  const closestAnchor = target.closest('a')
  const analEvent = closestAnchor?.dataset?.analEvent

  if (!analEvent) return

  window.gtag('event', analEvent)
})

export {}
