import { debounce } from 'lodash'

document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('select[data-autorefresh]').forEach((element) => {
    const select = element as HTMLSelectElement

    select.addEventListener('change', function () {
      const form = select.closest('form')

      if (!form) return

      let params: string[] = []

      form.querySelectorAll('select[name]').forEach((input) => {
        const select = input as HTMLSelectElement
        if (select.value) {
          params.push(`${select.name}=${select.value}`)
        }
      })

      const queryString = params.length > 0 ? '?' + params.join('&') : ''

      const urlPieces = [window.location.protocol, '//', window.location.host, window.location.pathname, queryString]

      window.location.href = urlPieces.join('')
    })
  })

  const leaderboardSelect: HTMLSelectElement | null = document.querySelector(
    '[data-leaderboard-select][name="leaderboard"]'
  )

  if (leaderboardSelect) {
    leaderboardSelect.addEventListener('change', function () {
      let pathname

      let queryString = ''

      let queryInput: HTMLInputElement | null = document.querySelector('[name="query"]')

      if (queryInput && queryInput.value) {
        queryString = `?query=${queryInput.value}`
      }

      pathname = `/leaderboard/${leaderboardSelect.value}`

      // Persist the country selected when changing leaderboard
      const urlParams = new URLSearchParams(window.location.search)
      let countryURLParam = ''
      if(urlParams.has('country') === true) {
        countryURLParam = '?country=' + urlParams.get('country')
      }

      const urlPieces = [window.location.protocol, '//', window.location.host, pathname, queryString, countryURLParam]

      window.location.href = urlPieces.join('')
    })
  }

  const countrySelect: HTMLSelectElement | null = document.querySelector(
    '[data-country-select][name="country"]'
  )

  if (countrySelect) {
    countrySelect.addEventListener('change', function () {
      const urlParams = new URLSearchParams(window.location.search)
      //let countryQueryString = urlParams.get('country')
      urlParams.set('country', countrySelect.value)

      window.location.search = urlParams.toString();
    })
  }

  const queryInput: HTMLInputElement | null = document.querySelector('[name="query"][data-autorefresh]')

  if (queryInput) {
    queryInput.addEventListener(
      'input',
      debounce(function () {
        const queryString = queryInput.value ? `?query=${queryInput.value}` : ''

        const urlPieces = [window.location.protocol, '//', window.location.host, window.location.pathname, queryString]

        window.location.href = urlPieces.join('')
      }, 500)
    )
  }
})
