// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import '@rails/ujs'
//
// import Turbolinks from 'turbolinks'
// import ActiveStorage from '@rails/activestorage'
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
//
// Turbolinks.start()
// ActiveStorage.start()

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '@/components/charts'
import '@/components/navbar'
import '@/components/sidebar'
import '@/components/forms'
import '@/components/analytics'

import { createApp } from 'vue'

import HomeLeaderboard from '@/components/home/Leaderboard.vue'
import HomeTwitchStreams from '@/components/home/TwitchStreams.vue'
import LeaderboardSearch from '@/components/leaderboard/Search.vue'

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-vue-app]').forEach((el) => {
    const app = createApp({})
    app.component('home-leaderboard', HomeLeaderboard)
    app.component('home-twitch-streams', HomeTwitchStreams)
    app.component('leaderboard-search', LeaderboardSearch)
    app.mount(el)
  })
})
