<script setup lang="ts">
import { debounce } from 'lodash'
import { ref, computed, onMounted, onUnmounted, reactive, defineProps } from 'vue'
import type { IPublicPlayersAutocompleteAPI } from '@/api/types'

const props = defineProps<{
  initialQuery?: string
  leaderboard: keyof typeof LEADERBOARDS
}>()

const DEBOUNCE = 100 // ms
const LEADERBOARDS = {
  rm_1v1: 'RM 1v1',
  qm_1v1: 'QM 1v1',
  qm_2v2: '2v2',
  qm_3v3: '3v3',
  qm_4v4: '4v4',
}

const emptyState = {
  leaderboard: LEADERBOARDS.rm_v1v1,
  count: 0,
  query: '',
  players: [],
}

const minQueryLength = 1

const query = ref(props.initialQuery || '')
const isOpen = ref(query.value.length >= minQueryLength)
const loading = ref(true)
const selectedIndex = ref(-1)
const suggestionsEl = ref()
const searchEl = ref()
const results: IPublicPlayersAutocompleteAPI = reactive(emptyState)
const leaderboard = computed(() => props.leaderboard ?? LEADERBOARDS.rm_v1v1)

if (query.value.length >= minQueryLength) {
  search(query.value)
}

async function search(q: string = '') {
  q = q.trim()
  if (q.length < minQueryLength) {
    Object.assign(results, emptyState)
    close()
    return
  }
  loading.value = true
  open()
  try {
    window.gtag('event', 'search', { search_term: q })

    const req = await fetch(
      `/api/v0/players/autocomplete?query=${encodeURIComponent(query.value)}&limit=10&leaderboard=${leaderboard.value}`
    )
    const data: IPublicPlayersAutocompleteAPI = await req.json()
    if (query.value === q) {
      Object.assign(results, data)
      selectedIndex.value = -1
    }
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

function moveSelectedIndex(change: number) {
  selectedIndex.value = Math.min(Math.max(selectedIndex.value + change, -1), results.players.length - 1)
  scrollSelectedIfOutsideScrollView()
}

function scrollSelectedIfOutsideScrollView() {
  const wrapper: HTMLDivElement = suggestionsEl.value
  const selectedEl = wrapper?.querySelector<HTMLDivElement>('.selected')
  if (!selectedEl) return

  if (selectedEl.offsetTop - wrapper.clientHeight > wrapper.scrollTop - selectedEl.clientHeight * 2)
    wrapper.scrollTo({ top: selectedEl.offsetTop, behavior: 'smooth' })
  else if (selectedEl.offsetTop - selectedEl.clientHeight < wrapper.scrollTop)
    wrapper.scrollTo({
      top: selectedEl.offsetTop - wrapper.clientHeight + selectedEl.clientHeight,
      behavior: 'smooth',
    })
}

function openSelected(e: KeyboardEvent) {
  if (selectedIndex.value > -1) {
    const el = suggestionsEl.value?.querySelector('.selected') as HTMLAnchorElement
    if (!el) return (window.location.href = `/players/${results.players[selectedIndex.value].profile_id}`)
    // Mimmick open in new tab behavior
    if (e.metaKey || e.ctrlKey) el.target = '_blank'
    el.click()
    el.target = ''
  }
}

function close() {
  setTimeout(() => {
    isOpen.value = false
  }, 200)
}

function open() {
  isOpen.value = true
}

function focus() {
  if (results.count) {
    open()
  } else {
    // happens when users goes back to search in browser
    search(searchEl.value.value)
    searchEl.value?.select()
  }
}

const debounceSearch = debounce((v) => search(v), DEBOUNCE)
const onInput = (v: string) => {
  query.value = v
  debounceSearch(v)
}

/** Watch for cmd + k and ctrl + k  */
function globalKeyupListener(e: KeyboardEvent) {
  if (e.key === 'k' && (e.metaKey || e.ctrlKey) && !window.location.pathname.startsWith('/explorer')) focus()
}

onMounted(() => window.addEventListener('keydown', globalKeyupListener))
onUnmounted(() => window.removeEventListener('keydown', globalKeyupListener))
</script>
<template>
  <div class="relative">
    <input
      type="text"
      @input="(e) => onInput(e.target.value)"
      @keydown.prevent.down="moveSelectedIndex(1)"
      @keydown.prevent.up="moveSelectedIndex(-1)"
      @keydown.prevent.enter="(e) => openSelected(e)"
      @keydown.prevent.esc="close()"
      @focus="focus()"
      @blur="close()"
      placeholder="Search players..."
      ref="searchEl"
      class="block w-full bg-gray-200 transition focus:bg-white text-black px-6 py-3 font-bold placeholder-gray-900 rounded-full focus:outline-none"
      :value="query"
    />
    <div
      class="absolute z-20 mt-2 bg-gray-700 border-2 border-white/20 rounded-xl z-10 max-h-[60vh] w-full right-0 overflow-hidden"
      v-if="isOpen && query.length >= 1"
    >
      <div class="max-h-96 overflow-auto" ref="suggestionsEl" v-if="results.count">
        <a
          v-for="(player, index) in results.players"
          :key="player.profile_id"
          :href="`/players/${player.profile_id}`"
          class="px-5 py-2 text-white block odd:bg-gray-800 hover:bg-gray-500"
          :class="{ '!bg-blue-700 !text-white selected': selectedIndex === index }"
        >
          <div class="font-bold truncate max-w-screen lg:max-w-md">{{ player.name }}</div>
          <div class="text-sm opacity-60 space-x-2">
            <template v-if="player.rank">
              <span>#{{ player.rank }}</span>
              <span>{{ player.rating }}</span>
              <span>{{ player.win_rate }}%</span>
            </template>
            <span v-else> Not on {{ LEADERBOARDS[leaderboard] }} leaderboard </span>
          </div>
        </a>
      </div>
      <div class="px-5 py-2 text-white text-sm text-white/40 border-t border-white/10">
        <template v-if="loading"> Loading <i class="fa-solid fa-spinner-third ml-m float-right fa-spin"></i></template>
        <template v-else-if="results.count">
          Use <i class="fas fa-arrow-down text-xs"></i> <i class="fas fa-arrow-up text-xs"></i> to select.
        </template>
        <template v-else> No results </template>
      </div>
    </div>
  </div>
</template>
