document.addEventListener('DOMContentLoaded', function() {
  const fixed = document.querySelector('[data-fixed]')

  if (!fixed) return

  const nav = document.querySelector('nav')
  const navHeight = nav.getBoundingClientRect().height
  const bounds = fixed.getBoundingClientRect()
  const fromTop = bounds.top + window.scrollY
  const width = bounds.width

  if (width > 0.5 * window.innerWidth) return

  let mode = null

  const scrollFn = function(e) {
    if (window.scrollY + navHeight >= fromTop && mode !== 'fixed') {
      fixed.style.position = 'fixed'
      fixed.style.top = `${navHeight}px`
      fixed.style.width = `${width}px`
      mode = 'fixed'
    } else if (window.scrollY + navHeight < fromTop && mode !== 'default') {
      console.log('setting default')
      fixed.style.position = ''
      fixed.style.top = ''
      fixed.style.width = ''
      mode = 'default'
    }
  }

  document.addEventListener('scroll', scrollFn)
  scrollFn()
})
