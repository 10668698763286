<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import { camelizeKeys } from 'humps'
import * as timeago from 'timeago.js'

import Search from '../leaderboard/Search.vue'
import consumer from '@/channels/consumer'
import type { ILeaderboard, IPlayer } from '@/api/types'
import twitchIconUrl from '../../../assets/images/social/twitch.svg?url'

const props = defineProps({
  labels: { type: Object, required: true },
  initialState: { type: Object, required: true },
})

const state: Ref<{ [key: string]: ILeaderboard }> = ref(props.initialState)
const activeLeaderboardKey: Ref<string> = ref('rm_solo')
const activeLeaderboard = computed(() => state.value[activeLeaderboardKey.value])

onMounted(() => {
  consumer.subscriptions.create(
    { channel: 'HomeChannel' },
    {
      received(data: any) {
        if (data.key === 'leaderboards') {
          state.value = data.value
        }
      },
    }
  )
})

function setLeaderboard(leaderboardKey: string) {
  activeLeaderboardKey.value = leaderboardKey
}

function isCurrentlyPlaying(player: IPlayer) {
  const lastGameAt = new Date(player.lastGameAt)
  return player.twitchIsLive || lastGameAt > new Date(Date.now() - 1000 * 60 * 10)
}
</script>

<template>
  <div class="py-8 px-6 flex flex-col gap-5 sm:p-8 md:flex-row md:items-center">
    <h3 class="font-bold text-2xl flex-auto whitespace-nowrap">
      <i class="fas fa-trophy mr-2"></i>
      Leaderboard
    </h3>
    <div class="flex gap-2 whitespace-nowrap">
      <button
        v-for="(label, key) in labels"
        :key="key"
        @click.prevent="setLeaderboard(key)"
        class="rounded px-2 sm:px-2 py-1 text-sm lg:text-base transition font-bold hover:bg-white hover:text-black"
        :class="[activeLeaderboardKey === key ? 'text-black bg-white/70' : 'bg-black/30']"
      >
        {{ label }}
      </button>
    </div>
    <!-- <Search :leaderboard="activeLeaderboardKey" /> -->
  </div>
  <div class="overflow-x-auto">
    <table class="table-auto w-full whitespace-nowrap">
      <thead>
        <tr class="text-gray-400 text-left text-sm uppercase tracking-wider">
          <th class="font-normal text-right pr-3">#</th>
          <th class="font-normal">Nickname</th>
          <th class="font-normal px-3">Rating</th>
          <th class="font-normal px-3">Win %</th>
          <th class="font-normal px-3">Games</th>
          <th class="font-normal px-3">Last Game</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="group even:bg-gray-900/20 group hover:bg-gray-500/20"
          v-for="player in activeLeaderboard.players"
          :key="player.profileId"
        >
          <td class="text-right text-gray-300 font-bold pr-3 pl-3 w-0 sm:pl-8">{{ player.rank }}.</td>
          <td class="flex gap-3 items-center py-5 pr-6 w-auto">
            <a :href="player.siteUrl">
              <img
                class="inline-block w-6 h-6 rounded outlineoutline-1 opacity-50 outline-black/70 group-hover:opacity-100 mr-2 md:mr-0"
                :src="player.avatars.small"
              />
            </a>
            <a class="whitespace-nowrap" :href="player.siteUrl">
              <span
                class="underline-offset-2 text-lg font-bold text-white whitespace-nowrap hover:underline"
                :href="player.siteUrl"
                >{{ player.name }}</span
              >
            </a>
          </td>
          <td class="text-gray-200 px-3 w-12">
            <a :href="player.siteUrl">{{ player.rating }}</a>
            <span v-if="player.lastRatingChange > 0" class="text-custom-green-500 ml-2">
              <i class="far fa-arrow-up text-sm"></i>
              {{ player.lastRatingChange }}
            </span>
            <span v-if="player.lastRatingChange < 0" class="text-custom-pink-500 ml-2">
              <i class="far fa-arrow-down text-sm"></i>
              {{ Math.abs(player.lastRatingChange) }}
            </span>
          </td>
          <td class="text-gray-200 px-3 w-12">
            <a :href="player.siteUrl">{{ player.winRate }} %</a>
          </td>
          <td class="text-gray-200 px-3 w-12">
            <a :href="player.siteUrl">{{ player.gamesCount }}</a>
          </td>
          <td class="text-gray-200 pl-3 pr-3 w-auto sm:pr-8">
            <a :href="player.twitchIsLive ? player.twitchUrl : player.siteUrl" target="_blank">
              <span v-if="isCurrentlyPlaying(player)" class="text-green-500">
                Playing
                <img v-if="player.twitchIsLive" class="inline h-6" :src="twitchIconUrl" />
              </span>
              <span v-else>{{ timeago.format(player.lastGameAt) }}</span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="py-5 px-6 flex items-center gap-2 sm:px-8">
      <a
        class="ml-auto font-bold text-gray-100 underline-offset-2 hover:underline hover:text-white"
        :href="activeLeaderboard.siteUrl"
      >
        View full leaderboard
        <i class="fas fa-arrow-right-long ml-2"></i>
      </a>
    </div>
  </div>
</template>
