<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import { camelizeKeys } from 'humps'
import * as timeago from 'timeago.js'
import { find } from 'lodash'

import consumer from '@/channels/consumer'
import type { ITwitchStream } from '@/api/types'

declare global {
  interface Window {
    Twitch: any
  }
}

const props = defineProps({
  initialState: { type: Object, required: true },
})

const isMobile = window.innerWidth < 768

const streams: Ref<ITwitchStream[]> = ref(props.initialState)
const activeStreamUserName = ref(streams.value[0].userName)

const activeStream = computed(() => find(streams.value, { userName: activeStreamUserName.value }))
const topStreams = computed(() => {
  return streams.value.slice(isMobile ? 1 : 0, isMobile ? 3 : 4)
})

let player: any = null

onMounted(() => {
  consumer.subscriptions.create(
    { channel: 'HomeChannel' },
    {
      received(data: any) {
        if (data.key === 'twitch_streams') {
          streams.value = data.value
        }
      },
    }
  )

  initializePlayer(activeStreamUserName.value)
})

function setActiveStream(event: Event, userName: string) {
  if (isMobile) {
    return
  } else {
    event.preventDefault()
  }

  activeStreamUserName.value = userName

  player.setChannel(userName)
}

function initializePlayer(userName: string) {
  if (isMobile) return

  const twitchPlayerId = 'twitch-player'

  const options = {
    width: '100%',
    height: '100%',
    channel: userName,
    autoplay: true,
    muted: true,
  }
  player = new window.Twitch.Player(twitchPlayerId, options)
}
</script>

<template lang="pug">
.w-full.flex.flex-wrap
  .w-full.flex-auto.px-1.mb-2(class='lg:w-4/5 lg:pr-2 lg:pl-0')
    a(v-if='isMobile' :href='activeStream.url' target='_blank')
      img.rounded-lg(:src='activeStream.thumbnailUrl')
    #twitch-player.rounded-3xl.h-full.overflow-hidden(v-else)
    .pl-2.pt-2
      div
        a(:href='activeStream.url' target='_blank')
          span.inline-block.mr-1
            svg(height='10' width='10')
              circle(cx='5' cy='5' r='5' stroke='black' fill='#a10300')
          span.text-white.font-bold
            | {{ activeStream.userName }}

          span.text-gray-300.ml-3
            | {{ activeStream.viewerCount }} viewers
      .pt-1
        a(:href='activeStream.url' target='_blank')
          span.text-sm.text-gray-300
            | {{ activeStream.title }}

  .w-full.flex.flex-wrap.content-start(class='lg:w-1/5')
    .px-1.pb-2.flex-none(
      v-for='stream in topStreams'
      class='w-1/2 lg:w-full lg:pl-3 lg:pr-0'
    )
      .relative(@click='setActiveStream($event, stream.userName)')
        a(:href='stream.url' target='_blank' data-anal-event='click_twitch_stream')
          img.rounded-lg.outline.outline-2(
            :class="[!isMobile && activeStreamUserName === stream.userName ? 'outline-white' : 'outline-black/50 opacity-50 group-hover:opacity-100']"
            :src='stream.thumbnailUrl'
          )

        .absolute.bottom-1.left-3
          a(:href='stream.url' target='_blank')
            span.inline-block.mr-1
              svg(height='10' width='10')
                circle(cx='5' cy='5' r='5' stroke='black' fill='#a10300')
            span.text-white.font-bold
              | {{ stream.userName }}

        .absolute.bottom-1.right-3
          a(:href='stream.url' target='_blank')
            | {{ stream.viewerCount }}
</template>
